import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import KenOmegaWeb3 from './landing_page';
import App from './App';
import './index.css'; // or './styles.css'

// Create a root container
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your app
root.render(<KenOmegaWeb3 />);

