import React, { useState } from 'react';

// Roadmap Component
const Roadmap = () => {
  const roadmapStages = [
    { 
      title: 'Phase 1: Ecosystem Launch', 
      details: [
        'KEN Governance Token Initial Distribution - DONE',
        'SKY Utility Token Introduction - DONE',
        'Volcano Run - Beta Release - Q1 2025'
      ]
    },
    { 
      title: 'Phase 2: Community Growth', 
      details: [
        'Expanded Gaming Partnerships',
        'Enhanced Tokenomics',
        'Shangri-La Node Launch',
        'Community Governance Initiatives'
      ]
    },
    { 
      title: 'Phase 3: Global Expansion', 
      details: [
        'Cross-Platform Game Integration',
        'International Marketing Campaign',
        'Advanced DAO Functionality, such as $KEN Token staking'
      ]
    }
  ];

  return (
    <section id="roadmap" className="bg-black text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">Ecosystem Roadmap</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {roadmapStages.map((stage, index) => (
            <div 
              key={index} 
              className="bg-gray-900 p-6 rounded-lg border border-gray-700 transform transition hover:scale-105"
            >
              <h3 className="text-xl font-semibold mb-4 text-blue-400">{stage.title}</h3>
              <ul className="list-disc pl-5 space-y-2">
                {stage.details.map((detail, idx) => (
                  <li key={idx} className="text-gray-300">{detail}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Main App Component
const KenOmegaWeb3 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-black text-white font-sans">
      {/* Navigation Bar */}
      <nav className="fixed top-0 left-0 right-0 bg-black z-50 h-[50px] flex items-center justify-between px-4 md:px-8">
        <div className="flex items-center">
          <a href="#" className="flex items-center">
          <img 
            src="https://kenomega.io/images/ken-snangri-la-favicon-2.png"
            alt="KEN Ecosystem Logo"
            //className="h-36 w-36 mr-4 mt-4"
            style={{ height: "100px", width: "100px", marginTop: "72px", marginLeft: "7px", zIndex: 100}}
          />
          <span className="text-xl font-bold" style={{ marginLeft: "15px", marginTop: "10px"}}>KEN OMEGA • Shangri-La</span>
          </a>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button 
            onClick={toggleMenu} 
            className="text-white focus:outline-none"
          >
            {isMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            )}
          </button>
        </div>
        
        {/* Desktop Navigation */}
        <div className="hidden md:flex space-x-6">
          <a href="#about" className="hover:text-blue-400 transition">About</a>
          <a href="#tokens" className="hover:text-blue-400 transition">Tokens</a>
          <a href="#whitePapers" className="hover:text-blue-400 transition">Whitepapers</a>
          <a href="#volcanoRun" className="hover:text-blue-400 transition">Volcano Run</a>
          <a href="#roadmap" className="hover:text-blue-400 transition">Roadmap</a>

        </div>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed top-[50px] left-0 right-0 bg-black md:hidden z-40">
          <div className="flex flex-col items-center space-y-4 py-4">
            <a href="#about" className="hover:text-blue-400" onClick={toggleMenu}>About</a>
            <a href="#tokens" className="hover:text-blue-400" onClick={toggleMenu}>Tokens</a>
            <a href="#whitePapers" className="hover:text-blue-400" onClick={toggleMenu}>Whitepapers</a>
            <a href="#volcanoRun" className="hover:text-blue-400" onClick={toggleMenu}>Volcano Run</a>
            <a href="#roadmap" className="hover:text-blue-400" onClick={toggleMenu}>Roadmap</a>
          </div>
        </div>
      )}

      {/* Main Content - Spacer for Fixed Navbar */}
      <div className="pt-[50px]">
        {/* About Section */}
        <section id="about" className="bg-black text-white py-16 px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-indigo-400 mb-6">sitrep: KEN OMEGA • Shangri-La</h1>
            <p className="text-xl text-gray-300 mb-8">
              KEN OMEGA • Shangri-La, or just Shangri-La for short, is a cutting-edge Web3 platform revolutionizing digital interaction
              through innovative governance and utility tokens, powered by GalaChain technology. Shangri-La will be the KEN Ecosystem blockchain channel.
            </p>
          </div>
        </section>

      {/* Tokens Section */}
      <section id="tokens" className="bg-gray-900 text-white py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Our Tokens</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {/* $KEN Token */}
            <div className="bg-black p-6 pt-24 rounded-lg relative">
              <h3 className="text-2xl font-semibold mb-4 text-blue-400">$KEN Token</h3>
              <img 
                src="https://kenomega.io/images/ken-token-logo-ipfs.png" 
                alt="$KEN Token Icon" 
                className="absolute top-6 right-6 h-[100px] w-[100px]"
              />
              <p className="mb-4">
                The governance token of the KEN Ecosystem, enabling community-driven 
                decision-making and strategic voting rights. Ken token will also be used for gas on Shangri-La. 
              </p>
              <div className="space-y-2">
                <a 
                  href="https://connect.gala.com/tokens/all" 
                  className="block bg-blue-600 text-white text-center py-2 rounded hover:bg-blue-700 transition"
                >
                  Get $KEN
                </a>
              </div>
            </div>

            {/* $SKY Token */}
            <div className="bg-black p-6 pt-24 rounded-lg relative">
              <h3 className="text-2xl font-semibold mb-4 text-green-400">$SKY Token</h3>
              <img 
                src="https://kenomega.io/images/sky-token-logo-ipfs.png" 
                alt="$SKY Token Icon" 
                className="absolute top-6 right-6 h-[100px] w-[100px]"
              />
              <p className="mb-4">
                The utility and reward token for the Secret Agent Sky web-shooter game 
                "Volcano Run", providing in-game advantages and earning opportunities.
              </p>
              <div className="space-y-2">
                <a 
                  href="https://connect.gala.com/tokens/all" 
                  className="block bg-green-600 text-white text-center py-2 rounded hover:bg-green-700 transition"
                >
                  Get $SKY
                </a>
              </div>
            </div>
          </div>

          {/* Bitrue Listing Notice */}
          <div className="mt-8 text-center bg-purple-600 p-4 rounded">
            <p className="text-sky-100">
              🔔 Listing on Bitrue Exchange for $KEN and $SKY is Currently Pending Review
            </p>
          </div>

          {/* Whitepapers */}
          <div id="whitePapers" className="mt-12 text-center">
            <h3 className="text-2xl font-semibold mb-6">Whitepapers</h3>
            <div className="flex justify-center space-x-4">
              <a 
                href="https://kenomega.io/ken-token-whitepaper.pdf" 
                target="_blank" 
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
              >
                $KEN Whitepaper
              </a>
              <a 
                href="https://kenomega.io/sky-token-whitepaper.pdf" 
                target="_blank" 
                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition"
              >
                $SKY Whitepaper
              </a>
            </div>
          </div>
        </div>
      </section>


        {/* Volcano Run Section */}
        <section id="volcanoRun" className="bg-black text-white py-16 px-4">
         <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-3xl font-bold mb-6">Secret Agent Sky™ - Volcano Run™ - Shooter Game</h1>
            <p className="text-xl text-gray-300 mb-8">
      Volcano Run is the shooter game that is scheduled for Beta release in Q1 2025. Weekly high score holders will be able to claim the $SKY Token Reward
      by using the CLAIM SKY button. The blockchain functionality for the automated $SKY Token release is currently in final testing stages on Galachain.
            </p>
            {/* Responsive Video Wrapper */}
            <div className="relative pb-[56.25%] h-0 overflow-hidden">
              <iframe
                src="https://player.vimeo.com/video/1034893972?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                title="SA Sky - Volcano Run - Leaderboard Logic Complete"
              ></iframe>
            </div>
          </div>
        </section>

        {/* Roadmap Component */}
        <Roadmap />

        {/* Footer */}
        <footer className="bg-black text-white h-[50px] flex items-center justify-center">
          <p className="text-white font-arial">© KenOmega.io</p>
        </footer>

        {/* Social Links */}
        <div className="bg-black text-white py-8 flex justify-center space-x-6">
          <a href="https://x.com/KenOmegaLtd" className="hover:text-blue-400 transition">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
            </svg>
          </a>
          <a href="#" className="hover:text-blue-400 transition">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
            </svg>
          </a>
          <a href="#" className="hover:text-blue-400 transition">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
            </svg>
          </a>
          <a href="#" className="hover:text-blue-400 transition">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.52-.024 3.48-1.728 3.48-1.728 0-3.456-1.536-6.252-1.536-6.252-1.536-1.152-3-1.116-3-1.116l-.168.192c1.836.564 2.684 1.368 2.684 1.368-1.116-.612-2.224-.924-3.252-.924-.78 0-1.524.108-2.196.3-.048.024-.084.048-.132.084-.252.132-.396.228-.396.228s.828-.792 2.64-1.368l-.12-.144s-1.464-.036-3 1.116c0 0-1.536 2.796-1.536 6.252 0 0 .948 1.704 3.468 1.728l.804-1.008c-1.152-.348-1.596-1.08-1.596-1.08l.252.156.036.024.036.024.012.012c.084.048.168.084.252.12.18.084.36.156.516.204.288.096.636.192 1.044.264.54.096 1.164.132 1.852.012.348-.072.696-.168 1.056-.324.252-.096.536-.24.828-.432 0 0-.456.756-1.644 1.092l.804.996zm-5.952-5.592c-.612 0-1.104.54-1.104 1.192 0 .652.496 1.192 1.104 1.192.612 0 1.104-.54 1.104-1.192.012-.652-.492-1.192-1.104-1.192zm3.924 0c-.612 0-1.104.54-1.104 1.192 0 .652.496 1.192 1.104 1.192.612 0 1.104-.54 1.104-1.192 0-.652-.492-1.192-1.104-1.192z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default KenOmegaWeb3;
